
import { defineComponent, computed, reactive, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import AppButton from '@/components/atoms/AppButton.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import { LocalStorageProcessingData } from '@/data/@types/LocalStorageProcessingData'
import AddonViewProcessingColor from './AddonViewProcessingColor.vue'
import AppSelect from '@/components/atoms/AppSelect.vue'
import { ImageViewer } from '@/helpers/imageviewer/ImageViewer'
import { useI18n } from 'vue-i18n'
import { printSizeList } from '@/data/selector/printSizeList'
import { useRouter } from 'vue-router'
import AutoClipToggleSwitch from '@/components/molecules/AutoClipToggleSwitch.vue'
import { getSelector } from '@/data/selector/getSelector'
import TwoInOneCoverPageToggleSwitch from '@/components/molecules/TwoInOneCoverPageToggleSwitch.vue'

const iv = ImageViewer.getInstance()

export default defineComponent({
  components: {
    AppButton,
    ButtonIcon,
    AppInput,
    AddonViewProcessingColor,
    AppSelect,
    AutoClipToggleSwitch,
    TwoInOneCoverPageToggleSwitch,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const i18n = useI18n()
    const router = useRouter()

    const currentProcessingData = computed(() => store.getters.processingData)
    const currentBundleNumber = computed(() => store.getters.bundleNumber)
    const currentBundleType = store.getters.contentType
    const currentBundleId = computed(() => props.item.contentsBundles[currentBundleNumber.value].id)
    const currentSelectionRange = computed(() => store.getters.processingData.selectionRange)
    const croppingSizeTextValue = computed(() => store.getters.processingData.croppingSizeTextValue)
    const croppedRange = computed(() => store.getters.processingData.croppedRange)
    const currentContent = computed(() => store.getters.contents?.[store.getters.contentNumber])

    const printSizeLocale = computed(() => getSelector('printSizeList', printSizeList, i18n.t))
    const resetProcessing = () => {
      store.commit('resetImageViewerSizeProcessingData')
      store.commit('resetImageViewerColorProcessingData')
    }
    const resetSizeProcessing = () => store.commit('resetImageViewerSizeProcessingData')
    const getProcessingDataFromLocalStorage = () => {
      try {
        return JSON.parse(localStorage.getItem('imageViewerProcessingData')!)
      } catch {
        console.error('ローカルストレージからのデータの取得に失敗しました')
        localStorage.removeItem('imageViewerProcessingData')
        return {}
      }
    }
    const setProcessingDataToLocalStorage = (processingData: string) => {
      localStorage.setItem('imageViewerProcessingData', processingData)
    }

    // ローカルストレージで各バンドルごとの調整タブの値を管理する
    let localStorageProcessingData: LocalStorageProcessingData
    const processingDataSync = () => {
      localStorageProcessingData = getProcessingDataFromLocalStorage()
      if (localStorageProcessingData[currentBundleId.value]) {
        store.commit('setImageViewerProcessingData', localStorageProcessingData[currentBundleId.value])
      } else {
        resetProcessing()
        localStorageProcessingData[currentBundleId.value] = currentProcessingData.value
        setProcessingDataToLocalStorage(JSON.stringify(localStorageProcessingData))
      }
    }

    if (currentBundleType !== 'image') return
    if (!localStorage.getItem('imageViewerProcessingData')) {
      setProcessingDataToLocalStorage('{}')
    }
    processingDataSync()

    watch(currentBundleId, () => {
      processingDataSync()
    })

    watchEffect(() => {
      localStorageProcessingData[currentBundleId.value] = currentProcessingData.value
      setProcessingDataToLocalStorage(JSON.stringify(localStorageProcessingData))
    })

    const rotateImage = (val: number) => {
      const currentDegree = currentProcessingData.value.degree
      const settingDegree = (currentDegree + val)
      store.commit('updateCurrentDegree', settingDegree % 360)
    }

    const currentDegree = computed({
      get: () => {
        return currentProcessingData.value.degree
      },
      set: (val: string) => {
        store.commit('updateCurrentDegree', Number(val))
      },
    })

    const croppingModeFlag = computed({
      get: () => store.getters.croppingModeFlag,
      set: (val: boolean) => {
        store.commit('updateCroppingModeFlag', val)
        // 切り抜かれた状態で範囲選択モードに入ったときは切り抜き範囲を初期化する
        if (val && croppedRange.value) {
          store.commit('setCroppedRange', { range: null, content: currentContent.value })
        }
      },
    })

    // セレクトボックスが選択されたとき、各サイズの初期値を矩形に設定する
    const initialSelectionRange = { left: 0, top: 0, width: 0, height: 0 }
    const updateCproppingSize = (width: number, height: number, text: string) => {
      initialSelectionRange.width = width
      initialSelectionRange.height = height
      store.commit('updateSelectionRange', initialSelectionRange)
      store.commit('updateCroppingSizeTextValue', text)
    }

    // 切り抜き範囲の矩形の変更
    const changeCroppingSize = (val: string) => {
      switch (val) {
        case '':
          updateCproppingSize(0, 0, '')
          break
        case 'A4':
          updateCproppingSize(210, 297, 'A4')
          break
        case 'B4':
          updateCproppingSize(257, 364, 'B4')
          break
        case 'A3':
          updateCproppingSize(297, 420, 'A3')
          break
        case 'A2':
          updateCproppingSize(420, 594, 'A2')
          break
        case 'A1':
          updateCproppingSize(594, 841, 'A1')
          break
        default:
          break
      }
    }

    const selectionRangeWidth = computed({
      get: () => {
        if (isNaN(currentSelectionRange.value.width)) return
        return Math.floor(currentSelectionRange.value.width)
      },
      set: (val) => {
        const selectionRange = {
          ...currentSelectionRange.value,
          width: Number(val),
        }
        store.commit('updateSelectionRange', selectionRange)
        store.commit('updateCroppingSizeTextValue', '')
      },
    })
    const selectionRangeHeight = computed({
      get: () => {
        if (isNaN(currentSelectionRange.value.height)) return
        return Math.floor(currentSelectionRange.value.height)
      },
      set: (val) => {
        const selectionRange = {
          ...currentSelectionRange.value,
          height: Number(val),
        }
        store.commit('updateSelectionRange', selectionRange)
        store.commit('updateCroppingSizeTextValue', '')
      },
    })

    // 範囲を選択モードの切替
    const changeCroppingMode = () => {
      if (croppingModeFlag.value) {
        // 範囲選択モードを終了するときは範囲を初期化する
        store.commit('updateSelectionRange', { left: 0, top: 0, width: 0, height: 0 })
      }
      croppingModeFlag.value = !croppingModeFlag.value
      store.commit('setCroppedRange', { range: null, content: currentContent.value })
    }

    // 切り抜きの実行
    const cropWithCurrentArea = () =>
      store.commit('setCroppedRange', { range: currentSelectionRange.value, content: currentContent.value })

    // 分割範囲
    const currentDivisionHorizontal = computed({
      get: () => store.getters.processingData.divisionHorizontal || 1,
      set: (val) => store.commit('updateDivisionHorizontal', restrictDivisionValue(val)),
    })
    const currentDivisionVertical = computed({
      get: () => store.getters.processingData.divisionVertical || 1,
      set: (val) => store.commit('updateDivisionVertical', restrictDivisionValue(val)),
    })
    // 分割範囲の値の範囲を制限する
    const divisionRange = { min: 1, max: 9 }
    const restrictDivisionValue = (value: number) => Math.min(Math.max(value, divisionRange.min), divisionRange.max)

    // 画像検索
    const URIToBlob = (dataURI: string) => {
      const byteString = atob(dataURI.split(',')[1])// byte列に変換
      const mimeType = dataURI.match(/:([a-z/-]+);/)![1]

      // byte列をBlobに変換する
      const buffer = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        buffer[i] = byteString.charCodeAt(i)
      }
      return new Blob([buffer], { type: mimeType })
    }

    const searchImage = async () => {
      try {
        const dataURI = iv.getCroppingAreaBitmap()
        if (!dataURI) return
        const blob = URIToBlob(dataURI)
        await store.dispatch('uploadFile', blob)

        router.push({
          name: 'SearchImageResult',
          query: {
            image: store.getters?.PictureFile.key,
          },
        })
      } catch (error: any) {
        // TODO: エラーハンドリングの検討
        console.log('error: ', error)
      }
    }

    const state = reactive({
      showMinimap: true,
      showSize: true,
      showColor: true,
      cutSize: 'A4',
      divX: 1,
      divY: 1,
      croppingMode: false,
      width: 0,
      height: 0,
    })

    return {
      state,
      resetSizeProcessing,
      rotateImage,
      currentDegree,
      croppingModeFlag,
      croppingSizeTextValue,
      changeCroppingSize,
      selectionRangeWidth,
      selectionRangeHeight,
      cropWithCurrentArea,
      currentDivisionHorizontal,
      currentDivisionVertical,
      searchImage,
      changeCroppingMode,
      printSizeLocale,
      divisionRange,
    }
  },
})
